@font-face {
  font-family: RobotoLight;
  src: url('./fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: RobotoBlond;
  src: url('./fonts/Roboto-Black.ttf');
}
@font-face {
  font-family: MonseratBold;
  src: url('./fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: MonseratBlack;
  src: url('./fonts/Montserrat-Black.ttf');
}
@font-face {
  font-family: MonseratRegular;
  src: url('./fonts/Montserrat-Regular.ttf');
}
html, body {
  background-color: #f0f0f0;
  font-family: 'MonseratRegular',
}
a {
  color: inherit;
  text-decoration: none !important;
}
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  display: none !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}
.card-products {
  background-color: #fff;
  border-radius: 50px;
  background-clip: content-box;
}
.card {
  --bs-card-border-radius: 25px !important;
  border: 0 !important;
  background: transparent !important;
}
.card-title {
  font-family: 'MonseratBold' !important;
  font-size: 16px !important;
  text-align: center;
}
.card-text {
  font-family: 'MonseratRegular' !important;
  font-size: 12px !important;
  text-align: center;
}
.btn-more {
  background-color: #e37b30 !important;
  font-family: 'MonseratRegular' !important;
  font-size: 12px !important;
  border: 0 !important;
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
}
.content {
  height: 100%;
  padding: 20px 20px 10px;
  color: #fff;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  color: #fff;
}
.header-contact{
  background-color: #C94B0A!important;
  border-radius: 10px;
  color:#fff !important;
  padding: 10px 15px;
}
.img-carrusel{
  width: 100%;
  align-items: center;

}
.nav-link {
  padding-left: 30px !important;
  color: #C94B0A !important;
  font-weight: bold !important;
}
.header{
 /*background: #f0f0f0;*/
  /*background-image: url('./img/header.png')!important;*/
  background: #fff;
  background-repeat: repeat-x;
  background-size: contain;
  font-size: 15px;
  padding: 20px !important;
}
.font-heder{
  color: #000;
  font-family: 'Roboto', sans-serif !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.second-secction{
  background-color: #000;
}
.animation-img {
  height: 60px;
  text-align: center;
}
.animation-img-big {
  width: 100%;
  text-align: left;
  padding: 20px 0;
}
.footer-contact{
  width: 100%;
  height:100%;
  padding: 0px;
}
.footer-contact-img {
  height:100%;
  visibility: hidden;
}
.footer-contact-contain{
  background-image: url('./img/contacto.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.footer-text{
  font-size: 18px!important;
  color: #000;
  font-weight: 700;
  cursor: pointer;
}
.footer-contact-form{
  background-image: url(./img/contacto.png);
  background-size: cover;
  background-position: top;
  background-color: #fff;
  font-size: 35px;
  text-align: center;
  font-weight: 400;
  padding: 100px 0 0 0;
}
.footer-contact-textareas{
  font-size: 15px;
  background: transparent!important ;
  color: #000;
  border: 1px solid #fff!important;
  border-radius: 20px !important;  
  padding: 20px 10px !important;
}
.div-button{
  text-align: center;
  width: 100%;
}
.button-footer{
  background-color: #B7014C!important;
  font-size: 25px !important;
  color:  #fff!important;
  font-weight: bold;
  padding: 10px 40px !important;
  border: 0 !important;
}
.social-media{
  width: 50px;
  font-size: 23px;
  padding-right: 0px;
  padding-left: 0px;  
}
.contact-media{
 font-size: 18px;
}
.footer-logos-bg{
  background: #CCDAAE;
  color: #99c1da;
  font-size: 25px;
  height: 120px;
}
.img-us{
  width: 100%;
}
.nosotros-bg{
  background-image: url('./img/background.jpg')!important;
  background-repeat:no-repeat;
  background-size: cover;
  background-color: #f0f0f0;
  background-position: top;
  padding: 150px 100px !important;
}
.nosotros-container {
  padding: 0 90px !important;
}
.nosotros-titulo{
  font-size: 100px;
  width: 100% !important;
  text-align: center;
  line-height: 105px;
  color: #fff;
  font-family: 'MonseratBold', sans-serif !important;
}
.nosotros-desc{
  font-size: 20px;
  width: 100% !important;
  text-align: center;
  line-height: 40px;
  color: #000;
  font-family: 'MonseratRegular', sans-serif !important;
}
.nosotros-negritas{
  font-family: 'MonseratBold', sans-serif !important;
}

.valores-bg {
  background-image: url('./img/valores.png')!important;
  background-repeat:no-repeat;
  background-size: cover;
  background-color: #fff;
  background-position: top;
  padding: 100px 100px !important;
}
.valores-img {
  width: 100%;
  padding: 30px 0;
}
.valores-contanier {
  text-align: center;
  max-width: 300px;
  padding-bottom: 40px;
}
.valores-title {
  font-size: 30px;
  font-weight: bold;
  color: #F89101;
}
.valores-desc {
  font-size: 24px;
  color: #000;
}
.clients-conteiner {
  background-color: #fff;
  background-position: top;
  padding: 100px 100px !important;
}


.cat-tab{
  border: #e27b30;
  background-color: #e27b30;
  padding: 10px;
  width: 80%;
  margin-right: 10px;
  --bs-nav-tabs-border-width:unset!important;
  border-radius: 35px;
  text-align: left;
}
.nav-tabs .nav-link {
  border-radius: 35px!important;
  background-color: #e27b30;
  color:#fff;
  /*height: 75px;*/
  width: 250px;
  padding: 5px !important;
}
.nav-item {
  padding-left: 15px !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color:#fff!important;
  border-radius: 25px;
  color:#e27b30!important;
  /*font-size: 25px!important;*/
  /*border-bottom: 0px solid transparent!important;*/
  border: 0 !important;
  font-weight: 500;
}
.catalogo-bg{
  background-image: url('./img/mandarinas.jpg')!important;
  background-repeat:no-repeat;
  background-size: cover;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}
.catalog-conteiner {
  background-image: url('./img/catalogo.png')!important;
  background-repeat:no-repeat;
  background-size: cover;
  background-color: #fff;
  background-position: top;
  padding: 50px 10px !important;
}
.catalogo-text {
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  line-height: 65px;
}
.catalogo-carru{
  width: 95%;
  height: 90%;
  padding: 40px;
}
.catalogo-title{
  font-size: 60px;
  color: #fff;
  font-family: 'MonseratBold', sans-serif !important;
  width: 500;

}
.catalogo-buttons{
  background-color: #FFC17E;
  border-radius: 50px;
  font-weight: bold;
  font-size: 70px;
  color:#fff;
  text-align: center;
  padding: 20px 60px;
  width: fit-content;
}

.marquee-img{
  width: 100%;
  max-height: 150px;
  padding: 15px 45px;
}

.external-link:visited {
  color: #e37b30; /* Mantener el color después de visitar el sitio */
}
.external-link {
  font-size: 18px;
  color: #e37b30;
  text-decoration: none;
}

.external-link:hover {
  text-decoration: underline;
}
.cat-header{
  background-image: url('./img/headercat.png')!important;
  background-repeat: repeat-x;
  background-size: contain;
 height: 110px;
 text-align: right;
}
.col-cat{
  border-left:2px solid #000 !important;
  text-align: left;
}
.menu-tabs {
  background-color: #e37b30;
  color: #fff;
  padding: 15px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  border-bottom: 0 !important;
}

.menu-label {
  position: relative;
  display: inline-block;
}
.menu-tab {
  position: relative;
}
.nuevo-label {
  position: absolute;
  top: -25px;
  right: -15;
  background-color: #7e9f5f;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 5px;
}
.house-cat{
  width: 40px;
  height: 40px;
}
.form-format{
  padding: 35px 45px 35px 45px;

}
.header-options {
  background-color: #e27b30;
  border-radius: 60px;
  padding: 10px;
}
.promo-btn {
  background-color: transparent !important;
  padding: 10px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  color: #fff !important;
  border: 0 !important;
}
.explore-btn {
  background-color: #fff !important;
  padding: 10px 20px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  color: #e27b30 !important;
  border: 0 !important;
  
}
.explore-div {
  border-radius: 60px; 
  background-color: #fff;
}
.section-space {
  height: 120px;
}
.section {
  padding: 50px 50px;
  width: 60% !important;
  background-color: #E69EB2;
  border: 1px solid #fff;
  border-radius: 40px;
}
.contacto-titulo{
  font-size: 70px;
  width: 100% !important;
  text-align: center;
  color: #fff;
  font-family: 'MonseratBold', sans-serif !important;
}
.footer-image {
  height: 90px;
}
.filter-text{
  color: #fff;
}
.btn-download {
  background-color: #fff !important;
  border-radius: 25px !important;
  text-align: center;
  border: 0 !important;
}
.download-container {
  text-align: right;
}
.arrow-button {
  width: 100px;
}
.custom-contaner {
  max-width: 1900px !important;
  padding: 0 50px !important;
}
.service-container {
  background-image: url(./img/services.png);
  background-size: cover;
  background-color: #CCDAAE;
}
.service-content {
  background: transparent !important;
  border: 0 !important;
  max-width: 1900px !important;
}
.service-title {
  color: #66932F;
  font-size: 70px;
  font-weight: 700;
}
.service-desc {
  color: #66932F;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
.service-item {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  padding: 15px 15px;
  background-color: #66932F;
  border-radius: 50px;
  text-align: center;
}
.service-product {
  position: relative;
  padding: 25px 5px;
  background-color: #90B067;
  border-radius: 100px;
  text-align: center;
  border: 20px solid #66932F;
  cursor: pointer;
  margin-bottom: 20px;
}
.service-img {
  position: relative;
  left: -15%;
  width: 130%;
  align-items: center;
  text-align: center;
}
.service-tecnique {
  margin-bottom: 20px;
}
.service-tecnique-text {
  background-color: #F0F4E6;
  width: 80%;
  height: 300px;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: #66932F;
  border-radius: 20px;
  line-height: 300px;
  text-align: center;
}
.back-icon {
  width: 50px;
  cursor: pointer;
}
.tec_1:hover {
  background-image: url(./img/services/grabado.png);
  background-size: cover;
  background-position: center;
  color: transparent;
}
.tec_2:hover {
  background-image: url(./img/services/sublimado.png);
  background-size: cover;
  background-position: center;
  color: transparent;
}
.tec_3:hover {
  background-image: url(./img/services/serigrafia.png);
  background-size: cover;
  background-position: center;
  color: transparent;
}
.tec_4:hover {
  background-image: url(./img/services/bordado.png);
  background-size: cover;
  background-position: center;
  color: transparent;
}
.service-item-modal {
  position: relative;
  background-size: cover;
  padding: 50px 150px !important;
  text-align: center;
}
.service-item-title {
  padding-top: 80px;
  font-size: 50px;
  font-weight: 700;
}
.service-item-desc {
  font-size: 25px;
  font-weight: 500;
  color: #000;
}
.service-item-img {
  width: 100%;
}
.service-item-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.background-mobile {
  background-image: url('./img/mancha.png');
  background-repeat:no-repeat;
  background-size: contain;
  background-position: center;
}

@media (max-width: 460px) {
  .nosotros-text {
    background-size: 0;
    padding: 15px!important;
    font-size: 13px;
    width: 90% !important;
    text-align: center;
    line-height: 20px;
  }
  .header {
    background-size: cover;
  }
  .contact-media {
    text-align: center !important;
    padding-bottom: 15px;
    font-size: 15px;
  }
  .catalogo-title {
    text-align: center;
    font-size: 35px;
  }
  .download-container {
    padding-top: 15px;
    text-align: center;
  }
  .nosotros-bg{
    
  }
  .animation-img {
    height: 45px;
  }
  .navbar-brand {
    margin-right: 0 !important;
  }
  .footer-logos-bg  {
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;
  }
  .footer-col {
    /*padding-top: 10px;
    padding-bottom: 10px;*/
  }
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 90% !important;
  }
  .contacto-titulo {
    font-size: 40px;
  }
  .form-format {
    padding: 35px 10px;
  }
  .clients-conteiner, .catalog-conteiner, .valores-bg, .nosotros-bg, .nosotros-container, .service-item-modal {
    padding: 10px !important;
  }
  .nosotros-titulo {
    font-size: 65px;
  }
  .marquee-img {
    padding: 0px 25px;
  }
  .catalogo-text {
    font-size: 30px;
    line-height: 35px;
    padding: 0
  }
  .catalogo-buttons {
    font-size: 33px;
  }
}